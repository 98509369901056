import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser, clearUser } from '../feature/user.Slice';
import OptIn from '../components/Optin';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

const regexEmail = /^[^@\s]{2,30}@[^\s@]{2,30}\.[^@\s]{2,}$/;
const regexPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-+.]).{8,}$/;

const apiPrefix = process.env.REACT_APP_API_PREFIX;

/**
 * @file
 * @module Pages/Profile
 * @description Profile est une page qui permet à un utilisateur de consulter et de modifier son profil.
 * - L'utilisateur peut modifier son nom d'utilisateur, son mot de passe, son prénom et son nom de famille.
 * - Cette page utilise le hook useEffect de React pour appeler l'API lors du chargement de la page.
 * - Cette page utilise le hook useSelector de 'react-redux' pour accéder aux informations de l'utilisateur connecté.
 * - Si lui-même est un chef d'entreprise, il peut également voir les informations de ses employés.
 * et exporter les informations de ses employés en format CSV.
 */

const Profile = () => {
    const [selectedTab, setSelectedTab] = useState('profile');
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user.user);
    const token = useSelector((state) => state.user.token);
    const optInStatus = user?.optin;
    const [error, setError] = useState('');
    const [username, setUsername] = useState(user.username);
    const [password, setPassword] = useState('');
    const [firstname, setFirstname] = useState(user.firstname);
    const [lastname, setLastname] = useState(user.lastname);
    const [company, setCompany] = useState(user.company);
    const [siret, setSiret] = useState(user.siret);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (selectedTab === 'other') {
            const fetchData = async () => {
                try {
                    const response = await fetch(apiPrefix + 'users/filter', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ siret, company }),
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setUsers(data);
                    } else {
                        console.error('Erreur lors de la récupération des utilisateurs', response.statusText);
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération des utilisateurs', error);
                }
            };

            fetchData();
        }
    }, [selectedTab, siret, company]);

    useEffect(() => {
        setUsername(user.username);
        setFirstname(user.firstname);
        setLastname(user.lastname);
    }, [user]);

    const handleUpdate = (e) => {
        e.preventDefault();

        if (!regexEmail.test(username)) {
            setError("Le nom d'utilisateur doit être une adresse e-mail valide.");
            return;
        }

        if (!regexPassword.test(password)) {
            setError("Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial.");
            return;
        }

        setError('');
        
        const data = { username, password, firstname, lastname };

        fetch(apiPrefix + `update/${user.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === 'Mise à jour réussie') {
                dispatch(updateUser({
                    id: user.id,
                    username,
                    firstname,
                    lastname,
                    lastactivity: user.lastactive,
                    is_admin: user.is_admin
                }));
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(clearUser());
        });
    };

    const formatLastActive = (lastActive) => {
        return !lastActive || new Date(lastActive).getFullYear() === 1970
            ? 'Pas d\'activité'
            : new Date(lastActive).toLocaleString();
    };

    const exportToCsv = () => {
        const csv = Papa.unparse(users);
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const csvURL = window.URL.createObjectURL(csvData);
        let tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'users.csv');
        tempLink.click();
    };

    const exportToExcel = () => {
        const fieldsToExport = [
            'id',
            'username',
            'firstname',
            'lastname',
            'lastactive',
            'company',
            'siret',
            'creationDate'
    
        ];
    
        // Filtrer les champs par utilisateur
        const filteredUsers = users.map(user => {
            const filtered = {};
            fieldsToExport.forEach(field => {
                filtered[field] = user[field];
            });
            return filtered;
        });
    
        const worksheet = XLSX.utils.json_to_sheet(filteredUsers);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Utilisateurs');
    
        XLSX.writeFile(workbook, 'users.xlsx');
    };

    return (
        <div className='containerProfils'>
            <div className='tabs'>
                <div
                    className={`tab ${selectedTab === 'profile' ? 'active' : ''}`}
                    onClick={() => setSelectedTab('profile')}
                >
                    Profil
                </div>
                {user.chefEntreprise && (
                    <div
                        className={`tab ${selectedTab === 'other' ? 'active' : ''}`}
                        onClick={() => setSelectedTab('other')}
                    >
                        Accès chef d'Entreprise
                    </div>
                )}
            </div>

            {selectedTab === 'profile' && (
                <div className='Profile'>
                    <div className='data'>
                        <h1>Profil</h1>
                        <div className='info'>
                            <p><i className="fas fa-user"></i> <span>Nom d'utilisateur</span> : {user.firstname}</p>
                            <p><i className="fas fa-user-tag"></i> <span>Prénom</span> : {user.lastname}</p>
                            <p><i className="fas fa-envelope"></i> <span>Email</span> : {user.username}</p>
                            <p><i className="fas fa-building"></i> <span>Société</span> : {user.company}</p>
                            <p><i className="fas fa-id-card"></i> <span>Siret</span> : {user.siret}</p>
                        </div>
                    </div>
                    <div className='Optin'>
    <h1>Abonnez-vous à notre Newsletter</h1>
    <p>Recevez les dernières nouvelles et mises à jour directement dans votre boîte de réception.</p>
    <OptIn username={username} optIn={optInStatus} />
</div>
                    <div className='Modify'>
                        <h1>Modifier mon profil</h1>
                        <p>Les champs marqués d'un astérisque (*) sont obligatoires</p>
                        {error && <p className='error'>{error}</p>}
                        <form onSubmit={handleUpdate}>
                            <label>
                                <i className="fas fa-user"></i> <span>Nom d'utilisateur</span>
                                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                            </label>
                            <label>
                                <i className="fas fa-key"></i> <span>Mot de passe *</span>
                                <input
                                    type="password"
                                    value={password}
                                    placeholder='******'
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                /> 
                            </label>
                            <label>
                                <i className="fas fa-user-tag"></i> <span>Prénom</span>
                                <input type="text" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                            </label>
                            <label>
                                <i className="fas fa-user"></i> <span>Nom de famille</span>
                                <input type="text" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                            </label>
                            <button type="submit" disabled={!password}>Mettre à jour</button>
                        </form>
                    </div>
                </div>
            )}

            {selectedTab === 'other' && (
                <div className='Profile'>
                    <div className='data company-data'>
                        <h1>Ma Société</h1>
                        <div className='company-info'>
                            {users.map((user) => (
                                <div key={user.id}>
                                    <i className="fas fa-user"></i> <span>{user.firstname} {user.lastname}</span><br />
                                    <i className="fas fa-envelope"></i> <span>{user.username}</span><br />
                                    <i className="fas fa-building"></i> <span>{user.company}</span><br />
                                    <i className='fas fa-clock'></i> <span>{formatLastActive(user.lastactive)}</span><br />
                                </div>
                            ))}
                        </div>
                        <button onClick={exportToExcel}>Exporter les utilisateurs en Excel</button>

                        {/* <button onClick={exportToCsv}>Exporter les utilisateurs en CSV</button> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Profile;
