import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AutoRedirect from '../components/Autologin';
import { isMobile } from 'react-device-detect';
import OptIn from '../components/Optin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faStore, faEnvelope, faNewspaper } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
    const user = useSelector((state) => state.user.user);
    const isLoggedIn = useSelector((state) => state.user.token !== null);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState(null);

    const optInStatus = user?.user?.optin;
    const username = user?.user?.username;
    const firstname = user?.user?.firstname;
    const userId = user?.user?.id;
    const apiPrefix = process.env.REACT_APP_API_PREFIX;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token && !isMobile) {
            const newUrl = `${window.location.origin}${window.location.pathname}/${token}`;
            window.history.replaceState(null, null, newUrl);
        }
    }, []);

    const handleRedirect = async (toMobileApp) => {
        try {
            const response = await fetch(`${apiPrefix}autologin/token/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) throw new Error('Impossible de récupérer le token');
            const data = await response.json();
            const token = data.token;

            if (toMobileApp) {
                window.location.href = `https://app.reducce.fr/autologin/${token}?organisation=CA-CEST-POUR-MOI`;
            } else {
                // ✅ Avant : window.open(...) → à éviter sur mobile
                window.location.href = `http://ca-cpourmoi.reducce.fr/autologin/${token}`;
            }
            
        } catch (err) {
            console.error(err);
            setError("Erreur lors de la redirection. Veuillez réessayer.");
        }
    };

    const redirectUrl = !isMobile ? "http://ca-cpourmoi.reducce.fr/autologin/" : null;

    return (
        <div className='Home'>
            <div className='hero-section'>
                <div className='hero-content'>
                    {isLoggedIn && <h2 className='welcome-message'>Bonjour {firstname}</h2>}
                    <h1>Bienvenue sur votre <br /> Comité d'entreprise !</h1>
                    <p>Découvrez des offres exclusives sur vos achats du quotidien.</p>

                    {!isMobile && redirectUrl && <AutoRedirect redirectUrl={redirectUrl} />}

                    {isMobile && (
                        <button
                            onClick={() => setShowModal(true)}
                            className="mt-4 bg-blue-600 text-white px-6 py-3 rounded-xl hover:bg-blue-700 transition"
                        >
                            Accéder à mes offres
                        </button>
                    )}

                    {showModal && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <div className="bg-white rounded-2xl p-6 shadow-xl max-w-md w-full text-center relative">
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="absolute top-2 right-3 text-gray-500 hover:text-black text-xl"
                                >
                                    &times;
                                </button>
                                <h2 className="text-xl font-semibold mb-4">Où souhaitez-vous aller ?</h2>
                                <p className="mb-6">Souhaitez-vous utiliser l'application mobile ou la version web ?</p>
                                <div className="flex justify-center gap-4">
                                    <button
                                        onClick={() => {
                                            setShowModal(false);
                                            handleRedirect(true);
                                        }}
                                        className="bg-blue-600 text-white px-4 py-2 rounded-xl hover:bg-blue-700 transition"
                                    >
                                        Application mobile
                                    </button>
                                    <button
                                        onClick={() => {
                                            setShowModal(false);
                                            handleRedirect(false);
                                        }}
                                        className="bg-gray-300 text-gray-800 px-4 py-2 rounded-xl hover:bg-gray-400 transition"
                                    >
                                        Version web
                                    </button>
                                </div>
                                {error && <p className="text-red-500 mt-4">{error}</p>}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Cartes : inchangées */}
            <div className='card-section'>
                <div className='card'>
                    <h3><span className='icon'><FontAwesomeIcon icon={faUser} /></span>Mon compte</h3>
                    <p>Bienvenue dans votre espace personnel. Ici, vous pouvez gérer vos informations personnelles et les mettre à jour, de manière simple et sécurisée.</p>
                    <Link to="/Profil">
                        <button className='card-button'>Mon compte</button>
                    </Link>
                </div>
                <div className='card'>
                    <h3><span className='icon'><FontAwesomeIcon icon={faStore} /></span>Référencer une enseigne</h3>
                    <p>Vous ne trouvez pas une enseigne que vous aimez ? Faites-nous part de vos suggestions ! Nous la contacterons pour l'ajouter à nos offres CE.</p>
                    <Link to="/ContactForm">
                        <button className='card-button'>Remplir le formulaire</button>
                    </Link>
                </div>
                <div className='card'>
                    <h3><span className='icon'><FontAwesomeIcon icon={faEnvelope} /></span>Contact</h3>
                    <p>
                        Pour toute réclamation sur la billetterie ou le paiement, contactez Ski Loisirs Diffusion au
                        <br /> 04 72 40 28 28 ou par mail à serviceclient@reducce.fr. Pour toute autre demande :
                    </p>
                    <a href="mailto:contact@ca-cpourmoi.com">
                        <button className='card-button'>Contactez nous !</button>
                    </a>
                </div>
                <div className='card'>
                    <h3><span className='icon'><FontAwesomeIcon icon={faNewspaper} /></span>Newsletter</h3>
                    <p>
                        Ne manquez rien ! Inscrivez-vous pour recevoir les bons plans de la semaine et des offres exclusives directement dans votre boîte mail.
                    </p>
                    {optInStatus !== undefined && (
                        <button className='card-button'>
                            <OptIn username={username} optIn={optInStatus} />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Home;
